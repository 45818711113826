import { useState, useEffect, createContext } from "react";

export const ReservContext = createContext({
  ReservMap: [],
});

export const ReservProvider = ({ children }) => {
  const [ReservMap, setReservMap] = useState([]);

  useEffect(() => {
    fetch("https://api.reverhotel.uz/contacts")
      .then((res) => res.json())
      .then((result) => {
        if (result.data && Array.isArray(result.data)) {
          setReservMap(result.data); // ✅ Faqat massivni olish
        } else {
          console.error("Unexpected API response:", result);
          setReservMap([]); // ✅ Agar xato bo‘lsa, bo‘sh massiv o‘rnatiladi
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []); // ✅ Cheksiz loopdan qochish

  const value = { ReservMap };

  return (
    <ReservContext.Provider value={value}>{children}</ReservContext.Provider>
  );
};
