import React, { useContext } from "react";
import { ReservContext } from "../../../context/reserv";
import { Wrapper } from "./styled-index";

const Contacts = () => {
  const { ReservMap } = useContext(ReservContext);

  console.log("ReservMap in Contacts:", ReservMap); // 🔥 Konsolda tekshirish

  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th>Checkin</th>
            <th>Departure</th>
            <th>Room</th>
            <th>PhoneNumber</th>
            <th>Adults</th>
            <th>Children</th>
            <th>Emails</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(ReservMap) && ReservMap.length > 0 ? (
            ReservMap.map((elem, index) => (
              <tr key={elem.contacts_id || index}>
                <td>{elem.check_in || "N/A"}</td>
                <td>{elem.departure || "N/A"}</td>
                <td>{elem.room || "N/A"}</td>
                <td>{elem.phone_number || "N/A"}</td>
                <td>{elem.adults || 0}</td>
                <td>{elem.kids || 0}</td>
                <td>{elem.email || "N/A"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No reservations available</td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Contacts;
