import styled from "styled-components";
export const WrapperList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 56px;
  background-color: #000;
  color: #fff;
  position: fixed;
  span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #06152b;
    opacity: 0.6;
    margin-left: 20px;
    transition: 0.5s;
  }
  ul {
    margin: 0;
    padding: 0;
  }
`;
export const WrapperItem = styled.div``;
